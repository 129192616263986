import React from 'react';
import styled from '@emotion/styled';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import ThemeContext from '../../context/ThemeContext';
import Djslist from '../../components/DjsList';
import RouterTracker from '../../components/RouterTracker';

const DjsStyles = styled.div(
  (props) => `
  padding: ${props.theme.size.base * 13}px 
    ${props.theme.size.base * 4}px 
    ${props.theme.size.base * 4}px;
  ${
    (props.breakpoints.lg || props.breakpoints.xl) &&
    `
   padding: ${props.theme.size.base * 8}px;
  `
  };
  
  .body {
    position: relative;
    z-index: 500;
    max-width: ${props.theme.size.base * 128}px;
    margin: 0 auto;
  }
`
);

const Djs = (props) => {
  const breakpoints = useBreakpoint();

  return (
    <DjsStyles theme={props.theme} breakpoints={breakpoints}>
      <RouterTracker />
      <div className='body typesetter'>
        <h1 className='title'>DJs</h1>
        <Djslist theme={props.theme} breakpoints={breakpoints} />
      </div>
    </DjsStyles>
  );
};

const Index = () => (
  <ThemeContext.Consumer>
    {(theme) => <Djs theme={theme} />}
  </ThemeContext.Consumer>
);

export default Index;
